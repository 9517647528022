import { Components } from 'formiojs';

const FieldComponent = (Components as any).components.field;

import editForm from './StepSummary.form';

/**
 * Here we will derive from the base component which all Form.io form components derive from.
 *
 * @param component
 * @param options
 * @param data
 * @constructor
 */
export default class StepSummary extends (FieldComponent as any) {
  constructor(component: any, options: any, data: any) {
    super(component, options, data);
  }

  static schema() {
    return FieldComponent.schema({
      type: 'stepSummary',
      parameters: 'something',
    });
  }
  public static editForm = editForm;

  static builderInfo() {
    return {
      title: 'StepSummary',
      group: 'basic',
      icon: 'fa fa-table',
      weight: 70,
      documentation: 'http://help.form.io/userguide/#table',
      schema: StepSummary.schema(),
    };
  }

  render() {
    let steps = '<div class="steps-container flow-root"><ul>';
    this.options.root.allPages.forEach((item: any, ind: any) => {
      steps += '<li>';
      steps += '<div class="relative pb-3">';

      if (ind !== this.options.root.allPages.length - 1) {
        steps += '<span class="absolute top-6 left-3.5 -ml-px h-full w-0.5 bg-gray-200 z-10" >';

        steps += '</span >';
      }

      steps += '<div class="relative flex space-x-3 items-start">';
      steps +=
        '<span class="h-7 w-7 rounded-full flex items-center justify-center z-50 flex-shrink-0 text-black font-bold bg-gray-200">';

      steps += ind + 1;
      steps += '</span>';
      steps += '<div class="flex flex-col justify-start">';
      steps += '<h4 class="pt-0.5 mt-0 mb-0 pb-0 text-base">';
      steps += item.component.title;
      steps += '</h4>';
      steps += '<p class="mb-0 pb-0 ">';
      steps += item.component.tooltip;
      steps += '</p>';
      steps += '</div>';
      steps += '</div>';
      steps += '</div>';
      steps += '</li>';
    });

    steps += '</ul></div>';

    return super.render(steps);
  }

  /**
   * Get the value of the component from the dom elements.
   *
   * @returns {Array}
   */
  getValue() {
    const value = this.lastValue;

    return value;
  }

  /**
   * Set the value of the component into the dom elements.
   *
   * @param value
   * @returns boolean
   */
  setValue(value: boolean) {
    return value;
  }
}
