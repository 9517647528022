import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { GLOBAL, FORM } from './config/pathnames/pathnames';
import './i18n';

require('dayjs/locale/nl');
require('dayjs/locale/en');
const TRACKING_ID = 'UA-136499979-1';
ReactGA.initialize(TRACKING_ID);
const Test = lazy(() => import('./pages/Form'));

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, []);

  return (
    <Suspense fallback="">
      <Routes>
        <Route path={`${FORM.ROOT}/*`} element={<Test />} />
        <Route path={GLOBAL.ROOT} element={<Navigate to={FORM.ROOT} />} />
      </Routes>
    </Suspense>
  );
};

export default App;
