/**
 * This file shows how to create a custom component.
 *
 * Get the base component class by referencing Formio.Components.components map.
 */
import { Components } from 'formiojs';

const FieldComponent = (Components as any).components.field;

import editForm from './Booking.form';

/**
 * Here we will derive from the base component which all Form.io form components derive from.
 *
 * @param component
 * @param options
 * @param data
 * @constructor
 */
export default class Booking extends (FieldComponent as any) {
  public checks: Array<Array<any>>;
  public lastValue = false;
  public domain: string;
  constructor(component: any, options: any, data: any) {
    super(component, options, data);
    this.checks = [];

    window.addEventListener('message', (message) => {
      if (message.data.type === 'booking.completed') {
        this.lastValue = true;
        this.updateValue();
      }
    });

    this.domain = window.location.hostname;
  }

  static schema() {
    return FieldComponent.schema({
      type: 'booking',
      parameters: 'something',
    });
  }

  public static editForm = editForm;

  static builderInfo = {
    title: 'Booking',
    group: 'basic',
    icon: 'fa fa-table',
    weight: 70,
    documentation: 'http://help.form.io/userguide/#table',
    schema: Booking.schema(),
  };

  get emptyValue() {
    return false;
  }

  // children: any

  public render() {
    return super.render(this.renderTemplate('booking', {}));
  }

  /**
   * After the html string has been mounted into the dom, the dom element is returned here. Use refs to find specific
   * elements to attach functionality to.
   *
   * @param element
   * @returns {Promise}
   */
  attach(element: any) {
    const refs: any = {};
    const { bookingToken, bookingWorkflow, customerId, orderId, ticketId, token } =
      this.data.customer_data;

    refs[this.component.key] = 'testiframe';
    this.loadRefs(element, refs);

    this.div_pdf = Array.prototype.slice.call(this.refs[this.component.key], 0)[0]; // Return an array of matching refs (with [this.component.key])

    const queryParamsArray = [];

    if (customerId) {
      queryParamsArray.push(`customer=${customerId}`);
    }

    if (ticketId) {
      queryParamsArray.push(`ticket_id=${ticketId}`);
    }

    if (bookingToken) {
      queryParamsArray.push(`token=${bookingToken}`);
    }

    if (token) {
      queryParamsArray.push(`token_id=${token}`);
    }

    if (orderId) {
      queryParamsArray.push(`order_id=${orderId}`);
    }

    const queryParams = queryParamsArray.join('&');

    if (this.domain === 'localhost' || this.domain === 'assistent.techsupport.bcc.nl') {
      this.div_pdf.innerHTML = `<iframe src="https://booking.techsupport.bcc.nl/workflow?pid=${bookingWorkflow}&${queryParams}" class="iframe-integration"></iframe></div>`;
    } else if (this.domain === 'assistent.nerds.nl') {
      this.div_pdf.innerHTML = `<iframe src="https://booking.nerds.nl/workflow?pid=${bookingWorkflow}&${queryParams}" class="iframe-integration"></iframe></div>`;
    } else if (this.domain === 'assistent.nl.tadoplus.com') {
      this.div_pdf.innerHTML = `<iframe src="https://booking.tadoplus.nl/workflow?pid=${bookingWorkflow}&${queryParams}" class="iframe-integration"></iframe></div>`;
    } else if (this.domain === 'assistent.plus.loqed.com') {
      this.div_pdf.innerHTML = `<iframe src="https://booking.plus.loqed.com/workflow?pid=${bookingWorkflow}&${queryParams}" class="iframe-integration"></iframe></div>`;
    }

    // Allow basic component functionality to attach like field logic and tooltips.
    return super.attach(element);
  }

  /**
   * Get the value of the component from the dom elements.
   *
   * @returns {Array}
   */
  getValue() {
    const value = this.lastValue;

    return value;
  }

  /**
   * Set the value of the component into the dom elements.
   *
   * @param value
   * @returns boolean
   */
  setValue(value: boolean) {
    return value;
  }
}
