import { initReactI18next } from 'react-i18next';
import { default as i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const defaultNS = 'common';

i18n.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'nl',
  ns: [],
  defaultNS,
});
