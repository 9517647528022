import nestedComponentForm from 'formiojs/components/_classes/nested/NestedComponent.form';
import StepSummaryEditDisplay from './editForm/StepSummary.edit.display';

export default function (...extend: any) {
  return nestedComponentForm(
    [
      {
        key: 'display',
        components: StepSummaryEditDisplay,
      },
    ],
    ...extend
  );
}
